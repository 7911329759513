<template>
  <div final-member-table-23s2>
    <h3>{{ title }}</h3>
    <div>
      <FinalMemberList :application="application" :rankInfo="rankInfo" :mark-size="markSize" />
      <div class="check-box" v-if="finished">
        <span class="check" />
        <span class="detail"> {{ benefitLegend }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import FinalMemberList from './FinalMemberList.vue';

export default {
  name: 'FinalMemberTable',
  components: { FinalMemberList },
  props: {
    title: String,
    benefitLegend: String,
    application: { type: Boolean, default: false },
    finished: { type: Boolean, default: false },
    markSize: Number,
    arenaId: String,
    groupMatch: { default: null },
  },
  data() {
    return {
      rankInfo: [],
    };
  },
  methods: {
    async getFinalResult() {
      const res = await this.$services.leaderboard.getFinalLeaderBoardList(this.arenaId);
      this.rankInfo = res.ranks.map(rank => ({ ...rank, hasSeed: this.groupMatch.find(m => m.name === rank.arenaApply.team.name)?.hasSeed }));
    },
    async getApplication() {
      const results = await Promise.all(this.arenaId.split(',').map(id => this.$services.leaderboard.getFinalApplication(id)));
      const items = [];
      results.forEach(r => items.push(...r.items));
      this.rankInfo = this.groupMatch.map(team => ({ team, ...items.find(e => e.team.name === team.name), teamGroupName: team.groupName, hasSeed: team.hasSeed }));
    },
  },
  mounted() {
    this.application
      ? this.getApplication()
      : this.getFinalResult();
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';
[final-member-table-23s2] { .tl;
  h3 { .fs(20, 38); .c(white); .tc; .mb(23); .w(270); .mh-c; .cover('@{pubg-result}/bg_result_sub_title.svg'); .bg-xc;
    &.final { .w(187);}
  }
  .check-box { .mt(15); .mb(60);
    .check {
      .ib; .wh(16); .bgc(#765793); border: 1px solid rgba(255, 255, 255, .2); .vam; .mr(6);
    }
    .detail { .fs(11); .c(white); .o(.5);}
  }

  @media (@tl-up) {
    h3 { .fs(36, 68); .mb(32); .w(470); .cover('@{pubg-result}/tl/bg_result_sub_title.svg'); }
    .check-box {
      .check { .wh(20);}
      .detail { .fs(13);}
    }
  }
}

</style>
