<template>
  <div final-member-list-23s2>
    <table>
      <thead>
        <tr>
          <th>RANK</th>
          <th>TEAM</th>
          <th>PLACEMENT</th>
          <th>KILL</th>
          <th>TOTAL</th>
        </tr>
      </thead>
      <tbody>
        <tr class="margin" />
        <tr v-for="(item, idx1) in rankInfo" :key="`${idx1}-item`" :class="{'seed': item.hasSeed, 'show-rank': !item.hasSeed && item.ranking && idx1 < markSize }">
          <td class="rank">{{ item.ranking || '-' }}</td>
<!--          <td>{{ getTeamGroupName(item) }}</td>-->
          <td class="team-info">
            <Avatar :info="getImgUrl(item)" />
            {{ getTeamName(item) }}
          </td>
          <td>{{ item.totalRankingPoint || '-' }}</td>
          <td>{{ item.totalKillPoint || '-' }}</td>
          <td>{{ item.totalPoint || '-' }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import Avatar from '@shared/components/common/Avatar.vue';

export default {
  name: 'FinalMemberList',
  components: { Avatar },
  props: {
    rankInfo: {
      type: Array,
      default: () => ([]),
    },
    markSize: {
      type: Number,
      default: 4,
    },
    application: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  methods: {
    getTeamGroupName(item) {
      return item.arenaApply?.teamGroupName || item.teamGroupName;
    },
    getTeamName(item) {
      return item.arenaApply?.team?.name || item.team?.name;
    },
    getImgUrl(item) {
      return item.arenaApply?.team || item.team;
    },
  },

};

</script>

<style lang="less">
@import '~@/less/proj.less';
[final-member-list-23s2] { .c(white); .fs(11); overflow-x: scroll;
  table { .tc; .min-w(518);
    thead { .h(35);
      tr {background-image: linear-gradient(135deg, #ff3d00, #7010d0 100%);
        th { border: 2px solid #1e2127; .bold;
          &:nth-of-type(1) { .w(10%); border-left: none;}
          &:nth-of-type(2) { .w(40%);}
          &:nth-of-type(3) { .w(20%);}
          &:nth-of-type(4) { .w(15%);}
          &:nth-of-type(5) { .w(15%); border-right: none;}
        }
      }
    }
    tbody {
      tr { .tc; .h(32);
        &.seed {
          td { .bgc(#4a3c57);}
        }
        &.show-rank {
          .rank {.bgc(#765793);}
          td { background: rgba(118, 87, 147, .5);}
        }
        td { border: 2px solid #1e2127; .bgc(rgba(118, 87, 147, .2));
          &:nth-of-type(1) { border-left: none; }
          //&:nth-of-type(2) { .p(0, 11);}
          //&:nth-of-type(3) {
          //  .tl;
          //}
          &:nth-of-type(2) {
            .tl;
          }
          &:nth-last-of-type(1) { border-right: none;}
        }
        .team-info {
          [avatar] { .ml(10); .mr(6); .wh(20); .vam; .br(4);}
        }

      }
      .margin { .h(7); .bgc(#1e2127);}
    }
  }

  @media (@tl-up) {
    .fs(13);
    table { .min-w(800);
      thead { .h(52);
        tr { .h(35);
          th { border-width: 4px;
            &:nth-of-type(1) { .w(10%); }
            &:nth-of-type(2) { .w(45%);}
            &:nth-of-type(3) { .w(15%);}
            &:nth-of-type(4) { .w(15%);}
            &:nth-of-type(5) { .w(15%);}
          }
        }
      }
      tbody {
        tr { .h(44);
          td {
            border-width: 4px;
          }
          .team-info {
            [avatar] { .ml(20); .wh(24); }
          }
        }
      }

    }
  }
}

</style>
